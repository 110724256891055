import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import { DistanceExplorer, FunctionDistanceExplorer, ManhattanCircle, ManhattanPaths, PAdicCalculator, PAdicFractalDistance, PAdicHeatChart, Sidebar, StringDistanceExplorer } from "story_components";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`When was the last time you went more than a day without thinking about distance?
It might seem like a strange question. But notions of distance, whether literal
or metaphorical, appear frequently in our daily lives.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here are some distance-related thoughts that regularly creep into my mind:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Where should I go for lunch today? What's `}<MDXTag name="em" components={components} parentName="p">{`nearby`}</MDXTag>{` and delicious?`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`What are my goals for today? How `}<MDXTag name="em" components={components} parentName="p">{`close`}</MDXTag>{` am I to meeting them?`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Is the world still on fire today? What can I do to help bring society `}<MDXTag name="em" components={components} parentName="p">{`closer`}</MDXTag>{` to
a more empathetic, thoughtful, and sustainable ideal?`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`With distance comes a notion of proximity: when things are close, and when they
are far away. Sometimes this proximity is objective and easy to measure. Other
times, it is more abstract. Regardless, nearly everyone has an intuition for
what distance means.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Mathematician `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Morris_Kline"
        }}>{`Morris Kline`}</MDXTag>{` once
wrote that "Mathematicians create by acts of insights and intuition." So it is
for distance. In what follows, I'd like to explore different examples of
distances in mathematics. We'll start with the most well-known formulation, and
from there will move on to more exotic examples. We'll see what ties all of
these distances together, and what makes each one of them unique.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Taxicab Calculations`}</MDXTag>
      <MDXTag name="p" components={components}>{`In geometry class, every student learns that the shortest distance between two
points is a straight line. And if you know the coordinates of those points, with
the help of the Pythagorean theorem you can even calculate this minimal
distance.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Connect the points with a horizontal and a vertical line segment; these segments
will form two sides of a right triangle. The hypotenuse of this triangle
represents the shortest distance.`}</MDXTag>
      <DistanceExplorer caption="Figure 1: Adjust the positions of the two points to see the shortest distance between them (orange)." />
      <MDXTag name="p" components={components}>{`When looking at a map, this shortest distance is often described by saying it's
the distance "as the crow flies." Unfortunately, if you're anything like me, you
are not a crow. For those of us tethered to the ground, this shortest distance
is typically unattainable. More often, you're constrained by the roads in the
town or city you live in.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Enter the `}<MDXTag name="strong" components={components} parentName="p">{`manhattan distance`}</MDXTag>{`, also known as the `}<MDXTag name="strong" components={components} parentName="p">{`taxicab geometry.`}</MDXTag>{` This
is a way of measuring distance that assumes you're constrained to grid. Such a
notion may seem strange in a math classroom, but in the real world we measure
distances in this way all the time (just ask Google Maps). We often talk about
distances between city locations in terms of blocks: how many over, and how many
up or down. This is just the manhattan distance.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In this geometry, there are no diagonals. From every point on the grid, you can
move in one of four directions: up, down, left, or right. Such a restriction
still leads to a perfectly good notion of distance. However, it comes with some
slightly surprising properties.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For one, unlike the notion of distance you learn about in high school geometry,
when it comes to connecting two points in the taxicab geometry, there is
typically `}<MDXTag name="em" components={components} parentName="p">{`no unique shortest path`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a demonstration that lets you explore how many different paths will
connect two points when using the manhattan distance. (As a bonus, can you
figure out how many possible paths there are of minimal distance between two
points on the grid?)`}</MDXTag>
      <ManhattanPaths caption="Figure 2: Click on a point on the grid, then adjust the slider to see all possible shortest paths to that point." />
      <MDXTag name="p" components={components}>{`For another fun bit of trivia, let's explore what circles look like in this
geometry. You can define a circle to simply be the set of points that are a
fixed distance from some central point. As you may recall, this fixed distance
is called the `}<MDXTag name="strong" components={components} parentName="p">{`radius`}</MDXTag>{` of the circle.`}</MDXTag>
      <MDXTag name="p" components={components}>{`With the manhattan distance on a grid, circles always consist of a finite number
of points. For instance, a circle of radius 1 centered at any point will consist
of the four points on either side of the center.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's how a circle looks in the taxicab geometry as you increase the radius:`}</MDXTag>
      <ManhattanCircle caption="Figure 3: Move the slider to adjust the size of a circle in the taxicab geometry. The red dot is at the center of the circle." />
      <MDXTag name="p" components={components}>{`As you can see, circles in this geometry become closer and closer to rotated
squares! Moreover, the circumference of any circle in this geometry is just the
radius `}<MDXTag name="em" components={components} parentName="p">{`r`}</MDXTag>{` multiplied by 8 (since in each quadrant, you need to move up by `}<MDXTag name="em" components={components} parentName="p">{`r`}</MDXTag>{`
units and over by `}<MDXTag name="em" components={components} parentName="p">{`r`}</MDXTag>{` units). As a consequence, the ratio of a circle's
circumference to twice its radius, which in traditional geometry has the value
`}{`π`}{` `}{`≈`}{` 3.14159..., has a distinctly less mysterious flavor here. As far
as the manhattan distance is concerned, `}{`π`}{` is simply equal to 4.`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Hamming it Up`}</MDXTag>
      <MDXTag name="p" components={components}>{`Whenever I write a story, I make typos. Fortunately, my computer has a spell
checker, and can easily help me correct my mistakes.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But how does the computer know when I've made a mistake in the first place? As
it turns out, the idea boils down to distance.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Intuitively, this makes sense. If I type `}<MDXTag name="strong" components={components} parentName="p">{`matehmatics`}</MDXTag>{` when I mean to type
`}<MDXTag name="strong" components={components} parentName="p">{`mathematics`}</MDXTag>{`, we can agree that the misspelled word is `}<MDXTag name="em" components={components} parentName="p">{`close`}</MDXTag>{` to the correctly
spelled word. But in order to make this intuition rigorous, we need a more
precise understanding of what "close" means in this context.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's take a look at three notions of distance between words instead of points.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Hamming Distance`}</MDXTag>
      <MDXTag name="p" components={components}>{`The `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Hamming_distance"
        }}>{`Hamming distance`}</MDXTag>{` is a
measurement you can use on words of the same length. It counts up the number of
positions at which the corresponding letters in the word are different.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For example, "ghost" and "roast" have a hamming distance of 3, because the first
three characters in the two words are different, while the last two are the
same.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Note the order of letters here is important! the "o" in "ghost" doesn't
count as a match with the "o" in "roast", because "o" is the third letter in
ghost but the second letter in roast.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Since the words you're comparing must have the same length, the Hamming distance
isn't practical as a spell-checking tool. See the references at the end
for more common applications of this distance function.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Levenshtein Distance`}</MDXTag>
      <MDXTag name="p" components={components}>{`If we want to compare words of different lengths, we need a different way to
measure distance. The `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Levenshtein_distance"
        }}>{`Levenshtein distance`}</MDXTag>{`
accomplishes this goal by generalizing, in some sense, the Hamming distance.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The Levenshtein distance calculates the distance between words as being the
number of single-character edits needed to transform one word to the other. An
edit is defined as the addition of a letter, the removal of a letter, or the
exchange of a letter at a given position for a different letter.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For example, the Levenshtein distance between "friend" and "foe" is 4: change
the "r" to an "o" and then delete the "i", "n", and "d."`}</MDXTag>
      <MDXTag name="p" components={components}>{`When the two strings are of equal length, the Hamming distance is an upper bound
for the Levenshtein distance, but sometimes the Levenshtein will be shorter. For
example, "tree" and "reed" have a Hamming distance of 4, but a Levenshtein
distance of only 2, since you can delete the "t" from the start of "tree" and
then add a "d" to the end.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Damerau-Levenshtein Distance`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's return to my original typo: matehmatics instead of mathematics. In this
case, the Hamming distance is well-defined, and like the Levenshtein distance it
equals 2.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In a 1964 paper titled "A technique for computer detection and correction of
spelling errors," Fred Damerau added a slight modification to the Levenshtein
distance. In what's now known as the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Damerau%E2%80%93Levenshtein_distance"
        }}>{`Damerau-Levenshtein distance`}</MDXTag>{`, swapping of
two adjacent characters counts as a single edit, rather than two. According to
this distance, matehmatics is only one edit away from mathematics.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Damerau motivated this change by observing that more than 80% of typos were
caused by one of the four edit types that the Damerau-Levenshtein distance
tracks: a missing letter, an extra letter, an incorrect letter, or two adjacent
letters out of order.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a demonstration for you to play around with these three notions of word
distance. (Bonus question: what would a circle look like in the geometries
determined by these distances?)`}</MDXTag>
      <StringDistanceExplorer caption="Figure 4: Type words into the boxes to compare three different distance measures." />
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Function Farness`}</MDXTag>
      <MDXTag name="p" components={components}>{`When it comes to math, we often think about distances between points. But points
aren't the only mathematical objects that can be compared for closeness.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We can also define a notion of distance on functions. In fact, we can define
infinitely many such notions!`}</MDXTag>
      <MDXTag name="p" components={components}>{`What might it mean for two functions to be close together or far apart? If you
think about it for a minute or two, you can probably come up with some ideas.
Here we'll explore two of them.`}</MDXTag>
      <MDXTag name="p" components={components}>{`One way might be to look at the `}<MDXTag name="strong" components={components} parentName="p">{`area`}</MDXTag>{` between the two functions. If the area
is large, then the functions must, in some sense, be far apart. Conversely, if
the area is 0, then under some reasonable assumptions on the functions, they
must be equal.`}</MDXTag>
      <Sidebar>
  For the mathematicians in the room, I'm intentionally omitting technical
  details. If it makes you feel better, you can assume that all of the functions I
  discuss in this section are continuous real-valued functions, defined on a
  closed and bounded interval.
      </Sidebar>
      <MDXTag name="p" components={components}>{`Another way to measure distance is to look at the `}<MDXTag name="strong" components={components} parentName="p">{`largest difference`}</MDXTag>{` between
the function values for a given input. For instance, if one function evaluates
to 100 while another one evaluates to 25 at the same input, the difference at
this input would be 75, and so the distance between the functions would be at
least 75. Once again, under some reasonable assumptions on the functions, if
this distance is 0, the functions are the same.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a demonstration where you can adjust some simple functions and see both
of these distances change.`}</MDXTag>
      <FunctionDistanceExplorer caption="Figure 4: You can adjust a few points on the orange and green function to explore how our notions of distance change." />
      <MDXTag name="p" components={components}>{`A question for you to ponder: if the largest difference distance is small, what
(if anything) can you say about the area distance? If the area distance is
small, what (if anything) can you say about the largest difference distance?`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`What's in a Distance?`}</MDXTag>
      <MDXTag name="p" components={components}>{`We've now seen several examples of distances. However, we haven't yet defined
precisely `}<MDXTag name="em" components={components} parentName="p">{`how`}</MDXTag>{` all of our examples fit into the same definition.`}</MDXTag>
      <MDXTag name="p" components={components}>{`With these examples at the forefront of our minds, I'd now like to tie them
together with a mathematical definition of a distance function.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Suppose you have a set of objects. These objects could be points, words,
functions, or any other collection of things that you'd like to be able to
describe in terms of proximity. A `}<MDXTag name="strong" components={components} parentName="p">{`distance function`}</MDXTag>{` for this set is function
that takes any two objects, returns a number, and has the following properties:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`The distance function never returns negative numbers. For example,
it doesn't make sense to talk about the distance between two cities as having a
negative value.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`If the distance between two objects is zero, those two objects are the same.
For example, regardless of the distance function you choose, the only word with
a distance of zero from "mathematics" is the word "mathematics" itself.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`The order in which you pass objects to the distance function doesn't matter.
For example, the distance between San Francisco and Chicago must be the same as the
distance between Chicago and San Francisco.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Adding destinations can never `}<MDXTag name="em" components={components} parentName="p">{`decrease`}</MDXTag>{` the total length of a trip. In other
words, the distance between A and C will never be more than the total distance
from A to B and then from B to C, no matter what B you choose. This requirement
is known as the `}<MDXTag name="strong" components={components} parentName="p">{`triangle inequality`}</MDXTag>{`, because in a triangle, the sum of any
two sides will always exceed the length of the third side.`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Intuitively, this should make sense: if you need to fly from San Francisco to
Chicago, then barring flight delays, you're never going to get a `}<MDXTag name="em" components={components} parentName="p">{`shorter`}</MDXTag>{`
trip by having a layover somewhere instead of taking a direct flight.`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`If you're interested, I'll leave it to you to prove that all of the examples
we've seen so far qualify as bonafide mathematical distances. (In general, the
triangle inequality is the hardest property to verify.)`}</MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Prime Time`}</MDXTag>
      <MDXTag name="p" components={components}>{`Now that we have a more formalized definition of distance, we can extend our set
of examples beyond where our intuition might naturally take us.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We'll begin with the first (and often only) distance function people typically
see: the absolute value function. This function measures the distance between
two numbers. For example, 2 and 5 are a distance of 3 from each other; put
another way, | 2 - 5 | = | 5 - 2 | = 3.`}</MDXTag>
      <MDXTag name="p" components={components}>{`You can verify for yourself that the absolute value function satisfies all of
the properties of a distance function.`}</MDXTag>
      <MDXTag name="p" components={components}>{`But there's another way we could define a distance, one that has to do with the
`}<MDXTag name="em" components={components} parentName="p">{`prime factors`}</MDXTag>{` of whole numbers. The distance is weird looking, but bear with me.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To start, recall that a prime number is a whole number larger than 1, whose only
prime factors are 1 and itself. The first few primes are 2, 3, 5, 7, and 11. 4
isn't prime because 4 = 2 `}{`×`}{` 2. 6 isn't prime because 6 = 2 `}{`×`}{` 3.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let's choose a prime number, say 3. Our new distance function will take any two
whole numbers, calculate their difference, and determine the maximum power of 3
that divides this difference. The distance is then defined as the reciprocal of
three to this maximal power. (In order to have a true distance, we define it to
equal zero if the difference equals zero.)`}</MDXTag>
      <MDXTag name="p" components={components}>{`It's a mouthful, right? Let's look at an example. Imagine that we're trying to
find the distance between the numbers 11 and 2. The difference is 9, which is
divisible by 3 two times. With our new notion of distance, then, we'd say that
the distance between 11 and 2 is 3`}<sup>{`-2`}</sup>{` = 1/9, or around 0.111.`}</MDXTag>
      <MDXTag name="p" components={components}>{`For a given prime `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`, this distance is called the `}<MDXTag name="strong" components={components} parentName="p">{`p-adic distance`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's a demonstration that will allow you to play around with these ideas in
more detail. You can select a prime and then calculate the `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance
between any two whole numbers within the range from -1,000,000 to 1,000,000.`}</MDXTag>
      <PAdicCalculator caption="Figure 5: Use this tool to calculating the p-adic difference between any numbers within the range from -1,000,000 to 1,000,000." />
      <MDXTag name="p" components={components}>{`Why in the world would you want to do this? I'm afraid a historical motivation
would take us too far afield, so if nothing else, just take it as a mathematical
curiosity for now.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Instead of calculating these distances one pair at a time, here's a heat map
with `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance for every pair of numbers between 1 and 25. As you can see,
distances are equal along diagonals that move up and to the right. This is
because adding the same value to two numbers has no effect on their `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic
distance: 10 - 2 has a 2-adic distance of 0.125, just as (10 + 5) - (2 + 5) = 15 - 7 does.`}</MDXTag>
      <PAdicHeatChart caption="Figure 6: A heat chart of p-adic distances for pairs of numbers between 1 and 25. The darker the square, the closer the distance to 1. The lighter the square, the closer the distance to 0. Hover over (or tap on) a square to see the numbers and their distance." />
      <MDXTag name="p" components={components}>{`While the chart above can show us several distance calculations at a glance, it
also has a flaw. By putting numbers along the top and right in increasing order
from 1 to 25, it implicitly relies on the standard notion of distance we've been
taught all of our lives. In other words, 25 is far away from 1 because the
standard distance (as measured by absolute value) is 24, even though the 2-adic
distance between 1 and 25 is much smaller.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Here's another way to visualize numbers based on their `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance, adapted
from `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.jstor.org/stable/2323809"
        }}>{`Visualizing the p-adic Integers`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://math.uni.lu/eml/projects/reports/P-adics.pdf"
        }}>{`The p-adic integers: General introduction and visual representation`}</MDXTag>{`. In this representation, numbers form a fractal-like structure. The numbers clump together quickly, so this demonstration only supports seeing a handful of them for the primes 3, 5, and 7:`}</MDXTag>
      <PAdicFractalDistance caption="Figure 7: Another representation of nearby numbers. Choose a prime, and then use the slider to see all numbers less than that prime (black), less then the square of that prime (black and blue), or less than the cube of that prime (black, blue, and orange). Points within a cluster are 'close' in the p-adic sense." />
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Classifying Closeness`}</MDXTag>
      <MDXTag name="p" components={components}>{`These `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distances give us an infinite family of distance functions in
addition to the more well-known absolute value function. But they also might
seem like little more than a curiosity. Here are some results that I hope will
convince you otherwise.`}</MDXTag>
      <MDXTag name="p" components={components}>{`First, it's possible to extend the `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance to differences between
rational numbers, not just integers. And when considered in this larger universe
of numbers, `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance functions represent emerge more naturally.
In fact, according to `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://en.wikipedia.org/wiki/Ostrowski%27s_theorem"
        }}>{`Ostrowski's Theorem`}</MDXTag>{`,
any distance function on pairs of rational numbers must be equivalent to one of the following:`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">{`The `}<MDXTag name="strong" components={components} parentName="li">{`trivial distance`}</MDXTag>{` function (i.e. one that sets the distance between any two numbers equal to 0 if the numbers are the same, and 1 if they are different),`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`The `}<MDXTag name="strong" components={components} parentName="li">{`absolute value`}</MDXTag>{` function,`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ol">{`The `}<MDXTag name="strong" components={components} parentName="li">{`p-adic distance`}</MDXTag>{` function for some prime `}<MDXTag name="em" components={components} parentName="li">{`p`}</MDXTag>{`.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`In other words, even though `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distances fall outside of our everyday
experience, mathematically they are of fundamental importance. `}</MDXTag>
      <MDXTag name="p" components={components}>{`Second, since there are infinitely prime numbers, in some sense it is the
absolute value function that's the outlier, not the notion of `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance.
In fact, there's active research at the intersection of physics and `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic
distance functions (see the resources at the end if you're curious). `}</MDXTag>
      <MDXTag name="p" components={components}>{`Third, there are some fun and counter-intuitive results in geometry that emerge
when working with `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance. Here are two to break your brain a little.
(These examples are taken from pages 5-6 in Neal Koblitz's
`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.461.4588&rep=rep1&type=pdf"
        }}>{`p-adic Numbers, p-adic Analysis, and Zeta-Functions`}</MDXTag>{`.)`}</MDXTag>
      <MDXTag name="ol" components={components}>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`Suppose you pick any three points with rational coordinates. As long as these
points don't fall along the same line, they'll determine the corners of a
triangle. If you calculate the distance between points using a `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance
function instead of the usual notion of distance, the triangle will `}<MDXTag name="em" components={components} parentName="p">{`always`}</MDXTag>{`
have two sides of equal length. In other words, every triangle is an isosceles
triangle!`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ol">
          <MDXTag name="p" components={components} parentName="li">{`You can define a circle of radius `}<MDXTag name="em" components={components} parentName="p">{`r`}</MDXTag>{` in the same way that you do with
well-known Euclidian geometry. Here's where things get weird: if you calculate
distance using a `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance function, `}<MDXTag name="em" components={components} parentName="p">{`every`}</MDXTag>{` point inside the circle is
a center of the circle!`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`  Put another way, just like there's no unique shortest path between two
points when using the manhattan distance, there's no unique center of a circle
when using a `}<MDXTag name="em" components={components} parentName="p">{`p`}</MDXTag>{`-adic distance.`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`Conclusion`}</MDXTag>
      <MDXTag name="p" components={components}>{`Distance may not seem like a very interesting topic. After all, it's something
we encounter every time we pull up Google Maps. But even in our everyday lives,
how we calculate distance depends on context. Are we trying to navigate through
crowded city streets, or find the shortest travel distance for an international
vacation?`}</MDXTag>
      <MDXTag name="p" components={components}>{`By formalizing our intuition into a definition, we can begin to explore a rich
universe of geometries based on different ways to measure distance. Some of
these are close to our experience, while others are quite foreign. But despite
their differences, they all share the same definition, and the potential for
rich and interesting mathematics.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Sources:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://stackoverflow.com/questions/2294915/what-algorithm-gives-suggestions-in-a-spell-checker"
            }}>{`What algorithm gives suggestions in a spell checker?`}</MDXTag></MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://www.oxfordmathcenter.com/drupal7/node/525"
            }}>{`Hamming Distance and Error Correcting Codes`}</MDXTag></MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://dl.acm.org/citation.cfm?doid=363958.363994"
            }}>{`A technique for computer detection and correction of spelling errors`}</MDXTag></MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.461.4588&rep=rep1&type=pdf"
            }}>{`p-adic Numbers, p-adic Analysis, and Zeta-Functions`}</MDXTag>{`, by Neal Koblitz`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://www.jstor.org/stable/2323809"
            }}>{`Visualizing the p-adic Integers`}</MDXTag>{`, by Albert A. Cuoco.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "http://math.uni.lu/eml/projects/reports/P-adics.pdf"
            }}>{`The p-adic integers: General introduction and visual representation`}</MDXTag>{`, by Brian Courthoute, Pablo Guzman, and Antoine Ronk.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://en.wikipedia.org/wiki/Ostrowski%27s_theorem"
            }}>{`Ostrowski's Theorem`}</MDXTag>{`.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://link.springer.com/article/10.1134%2FS2070046609010014"
            }}>{`On p-adic mathematical physics`}</MDXTag>{`, by B. Dragovich, A. Yu. Khrennikov, S. V. Kozyrev, and I. V. Volovich.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li"><MDXTag name="a" components={components} parentName="p" props={{
              "href": "https://link.springer.com/article/10.1134/S2070046610010061"
            }}>{`Number theory as the ultimate physical theory`}</MDXTag>{`, by Igor V. Volovich.`}</MDXTag>
        </MDXTag>
      </MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "Keeping Distances",
  "date": "2019-07-01",
  "featured_image": "../../images/featured_images/taxicab_view.jpg",
  "caption": "On the intersection between Google maps, spelling mistakes, and prime numbers.",
  "featured_image_caption": "View from the top of a taxi. Photo by Clem Onojeghuo on Unsplash.",
  "outline": true,
  "tags": ["geometry", "number theory", "functions"]
};
      